import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Container } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import Img3 from "./imgs/motorcycle5.jpg";
import "./home.css";
import "./fonts.css";
import PhoneIcon from "@mui/icons-material/Phone";
import { motion } from "framer-motion/dist/framer-motion";

export const About = () => {
  const year = new Date().getFullYear();
  useEffect(() => {
    document.title = "About Onetakes";
    document.body.style.backgroundColor = "#333333";
    scroll.scrollToTop(0, 0);
  }, []);
  const matches = useMediaQuery("(min-width:768px)");
  return (
    <motion.div
      initial={{
        opacity: 0,
        transition: { duration: 50 },
      }}
      animate={{
        opacity: 1,
        transition: { duration: 1 },
      }}
    >
      <div className="text-center" style={{ backgroundColor: "#262626" }}>
        <div style={{ paddingTop: !matches ? "25%" : "10%", color: "white" }}>
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2
              style={{
                color: "white",
                fontSize: matches ? "" : "150%",
                paddingTop: matches ? "1%" : "5%",
              }}
              className="xyz20"
            >
              About Us
            </h2>
          </div>
          <div
            style={{
              marginBottom: "5vh",
              marginTop: matches && 100,
              // minHeight: !matches && "50vh",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102971.66834508658!2d139.7197543554281!3d36.24278210657786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x859cc92edef90347%3A0x50d1f32dcaee08bc!2z5qCq5byP5Lya56S-44Ov44Oz44OG44Kk44Kv44K5!5e0!3m2!1sen!2sng!4v1672061645133!5m2!1sen!2sng"
              style={{
                border: "0",
                width: matches ? "80vw" : "90vw",
                height: matches ? "70vh" : "50vh",
              }}
              loading="lazy"
              title="location"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          <div>
            {/* <Container fluid> */}
            <div
              style={{
                textAlign: "center",
                paddingTop: matches ? "10px" : "0px",
              }}
            >
              <div>
                <div
                // className="col-sm-12"
                // style={{
                //   position: "relative",
                //   width: "100%",
                //   marginLeft: "auto",
                //   marginRight: "auto",
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: "white",
                        fontWeight: "lighter",
                        fontSize: !matches ? "large" : "140%",
                      }}
                    >
                      <Container fluid={!matches && true}>
                        <table
                          style={{
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <tbody>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>会社名 </td>
                              <td>:</td>
                              <td> 株式会社ワンテイクス</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>英文社名 </td>
                              <td>:</td>
                              <td> Onetakes Co., Ltd.</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>代表取締役</td>
                              <td>:</td>
                              <td>植竹将貴</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>創業</td>
                              <td>:</td>
                              <td>2008年</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>本社所在地</td>
                              <td>:</td>
                              <td>〒323-0816 栃木県小山市南和泉518</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>TEL</td>
                              <td>:</td>
                              <td>
                                <a
                                  style={{ color: "white" }}
                                  href="tel:0285387823"
                                >
                                  0285-38-7823 <PhoneIcon className="call-me" />
                                </a>
                              </td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>FAX</td>
                              <td>:</td>
                              <td>0285-38-7824</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>資本金</td>
                              <td>:</td>
                              <td>300万円</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>主要取引銀行</td>
                              <td>:</td>
                              <td>足利銀行　栃木銀行</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                              }}
                            >
                              <td>インボイス登録番号</td>
                              <td>:</td>
                              <td>T1060001034288</td>
                            </tr>
                            <tr
                              style={{
                                color: "white",
                                borderTop: "1px white groove",
                                borderBottom: "1px white groove",
                              }}
                            >
                              <td>古物商登録番号</td>
                              <td>:</td>
                              <td>第411070002314号</td>
                            </tr>
                          </tbody>
                        </table>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                paddingTop: matches ? "10%" : "0%",
                // paddingBottom: "10%",
              }}
            >
              <img
                style={{
                  width: "100%",
                }}
                src={Img3}
                className="img-responsive"
                alt="about"
              />
            </div>
            {/* </Container> */}
          </div>
        </div>
        <div id="footer" style={{ backgroundColor: "#321f63", width: "100%" }}>
          <div className="container text-center">
            <p>©︎ {year} Kabushikigaisha Onetakes</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
