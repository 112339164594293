import useMediaQuery from "@mui/material/useMediaQuery";
import Img from "./imgs/motorcycle7.jpg";
import { useEffect } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { animateScroll as scroll } from "react-scroll";

export const Principles = () => {
  const year = new Date().getFullYear();
  useEffect(() => {
    document.title = "Principles";
    document.body.style.backgroundColor = "#333333";
    scroll.scrollToTop(0, 0);
  }, []);
  const matches = useMediaQuery("(min-width:1020px)");
  return (
    <motion.div
      initial={{
        opacity: 0,
        transition: { duration: 50 },
      }}
      animate={{
        opacity: 1,
        transition: { duration: 1 },
      }}
    >
      <div style={{ backgroundColor: "#262626" }}>
        <div>
          <div className="container" style={{ textAlign: "center" }}>
            <div className="section-title center" style={{ color: "white" }}>
              <h2
                style={{
                  color: "whitesmoke",
                  paddingTop: !matches ? "30%" : "10%",
                  fontSize: !matches ? "133%" : "",
                }}
                className="xyz20"
              >
                Principles
              </h2>
              <br />
              <div
                className="container"
                style={{
                  background:
                    "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                  paddingTop: "5%",
                  marginTop: "5%",
                  paddingBottom: "3%",
                  borderRadius: "30%",
                  fontWeight: 500,
                  fontSize: matches ? "140%" : "100%",
                }}
              >
                事業を通じて生活・未来がホッと安心のできる社会作りに貢献します。
              </div>
              <br />
              <div
                className="container"
                style={{
                  background:
                    "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                  paddingTop: "5%",
                  marginTop: "5%",
                  paddingBottom: "3%",
                  borderRadius: "30%",
                  fontWeight: 500,
                  fontSize: matches ? "140%" : "100%",
                }}
              >
                株式会社ワンテイクス
                <br />
                代表取締役　植竹将貴
              </div>
            </div>
          </div>
          <div>
            <img
              src={Img}
              className="img-responsive"
              alt="cool motorcycle"
              style={{
                // marginTop: !matches && "-10%",
                marginRight: "auto",
                width : "100vw",
                height: "56.272vw",
                marginLeft: "auto",
              }}
            />
          </div>
        </div>
        <div
          id="footer"
          style={{
            backgroundColor: "#321f63",
            width: "100%",
            bottom: 0,
            position: "relative",
            marginTop: "10vh",
          }}
        >
          <div className="text-center">
            <p>©︎ {year} Kabushikigaisha Onetakes</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
