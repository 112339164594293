import useMediaQuery from "@mui/material/useMediaQuery";
import Img from "./imgs/motorcycle6.jpg";
import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import LightModeIcon from "@mui/icons-material/LightMode";
import ForestIcon from "@mui/icons-material/Forest";
import Ramen from "@mui/icons-material/RamenDining";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion/dist/framer-motion";
import Img2 from "./imgs/instagram.JPG";
import Mdg from "./imgs/sdgs.jpg";
import Video from "./imgs/ad.mp4";
import Stories from "react-insta-stories";
import Box from "@mui/material/Box";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

export const BusinessLineup = () => {
  const year = new Date().getFullYear();
  const tabs = useMediaQuery("(max-width:992px)");
  const mini = useMediaQuery("(min-width:430px)");
  const landscape = useMediaQuery("(max-height:440px)");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    bgcolor: "transparent",
    boxShadow: 24,
    // p: 4,
  };

  useEffect(() => {
    document.title = "Business Lineup";
    document.body.style.backgroundColor = "#333333";
    !tabs &&
      scroll.scrollTo(300, {
        duration: 3000,
        delay: 500,
        smooth: true,
        offset: 50, // Scrolls to element + 50 pixels down the page
      });
    tabs && scroll.scrollToTop();
  }, []);
  const matches = useMediaQuery("(min-width:1020px)");
  const stories = [
    {
      type: "video",
      // url: "http://localhost:3000/static/media/ad.89237f0d.mp4",
      url: "https://one-takes.com/static/media/ad.89237f0d.mp4",
    },
  ];
  return (
    <motion.div
      initial={{
        opacity: 0,
        transition: { duration: 50 },
      }}
      animate={{
        opacity: 1,
        transition: { duration: 1 },
      }}
    >
      <div style={{ backgroundColor: "#262626", overflowX: "hidden" }}>
        <div>
          <div className="" style={{ textAlign: "center" }}>
            <div
              className="section-title center"
              style={{ color: "white", marginBottom: 0 }}
            >
              <h2
                style={{
                  color: "white",
                  paddingTop: !matches ? (landscape ? "10%" : "25%") : "10%",
                  fontSize: !matches ? "133%" : "",
                }}
                className="xyz20"
              >
                Business Lineup
              </h2>
              <br />
              <div>
                <img
                  src={Img}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100vw",
                    height: "37.40023vw",
                  }}
                  className="img-responsive"
                  alt="our helmets"
                />{" "}
              </div>
              <div className={matches ? "row" : ""}>
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <div className="col-sm-6">
                      <LightModeIcon
                        sx={{ fontSize: "3vw", marginBottom: "3px" }}
                      />
                      <br />
                      <p>Future of Energy</p>
                    </div>
                    <div className="col-sm-6" style={{ textAlign: "left" }}>
                      <p>
                        大陽光ソーラーパネルの保守管理(敷地内草刈り、立木伐採など含む)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "15%",
                      background:
                        "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                    }}
                  >
                    <table
                      style={{
                        marginTop: "5%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            color: "white",
                          }}
                        >
                          <td style={{ paddingLeft: "6%", fontSize: "10px" }}>
                            <LightModeIcon
                              sx={{ fontSize: "8vw", marginBottom: "7px" }}
                            />
                            <br />
                            Future of Energy
                          </td>
                          <td
                            style={{
                              justifyContent: "right",
                              width: "2.5%",
                              paddingLeft: mini ? "1vw" : "1%",
                            }}
                          >
                            大陽光ソーラーパネルの保守管理(敷地内草刈り、立木伐採など含む)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <div className="col-sm-6">
                      <Icon
                        icon="fluent-mdl2:web-environment"
                        style={{ fontSize: "3vw", marginBottom: "7px" }}
                      />
                      <br />
                      <p>Environment </p>
                    </div>
                    <div className="col-sm-6" style={{ textAlign: "left" }}>
                      <p>敷地管理(草刈り、伐採、伐根)</p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "15%",
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                    }}
                  >
                    <table
                      style={{
                        marginTop: "5%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            color: "white",
                          }}
                        >
                          <td style={{ paddingLeft: "6%", fontSize: "10px" }}>
                            <Icon
                              icon="fluent-mdl2:web-environment"
                              style={{ fontSize: "8vw", marginBottom: "7px" }}
                            />
                            <br />
                            Environment
                          </td>
                          <td
                            style={{
                              justifyContent: "right",
                              paddingLeft: mini ? "5vw" : "0%",
                              width: "3.5%",
                            }}
                          >
                            敷地管理(草刈り、伐採、伐根)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className={matches ? "row" : ""}>
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <div className="col-sm-6">
                      <Icon
                        icon="fontisto:recycle"
                        style={{ fontSize: "3vw", marginBottom: "7px" }}
                      />
                      <br />
                      <p>Sustainability</p>
                    </div>
                    <div className="col-sm-6" style={{ textAlign: "left" }}>
                      <p>総合リサイクル(中古物販売、他) </p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "15%",
                      background:
                        "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                    }}
                  >
                    <table
                      style={{
                        marginTop: "5%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            color: "white",
                          }}
                        >
                          <td style={{ paddingLeft: "6%", fontSize: "10px" }}>
                            <Icon
                              icon="fontisto:recycle"
                              style={{ fontSize: "8vw", marginBottom: "7px" }}
                            />
                            <br />
                            Sustainability
                          </td>
                          <td
                            style={{
                              paddingLeft: mini ? "8vw" : "0%",
                              width: "4%",
                            }}
                          >
                            総合リサイクル(中古物販売、他)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/one_takestyles/"
                      style={{ color: "white" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="col-sm-6">
                        <ColorLensIcon sx={{ fontSize: "3vw" }} />
                        <p>Design</p>
                      </div>
                      <div className="col-sm-1" style={{ right: "5vw" }}>
                        <img src={Img2} className="flexer-img-ig2" alt="ig" />
                      </div>
                      <div className="col-sm-6" style={{ textAlign: "left" }}>
                        <p style={{ fontWeight: "800", marginTop: -40 }}>
                          ロゴ、ステッカー、看板のデザイン、商用ホームページ作成（写真撮影含む）、SEO対策、ブログ作成、ブログ記事作成代行、商用スマホアプリ開発、YouTubeビデオ、および企業PRビデオの作成
                        </p>
                      </div>
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "15%",
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/one_takestyles/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <table
                        style={{
                          marginTop: "5%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <tbody>
                          <tr
                            style={{
                              color: "white",
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "6%",
                                // paddingRight: "2%",
                                fontSize: "10px",
                              }}
                            >
                              <ColorLensIcon
                                sx={{ fontSize: "8vw", marginBottom: "7px" }}
                              />
                              <br />
                              Design
                            </td>
                            <td style={{ paddingLeft: "0%" }}>
                              <img
                                src={Img2}
                                className="flexer-img-ig img-responsive"
                                alt="ig"
                                style={{
                                  color: "white",
                                  borderRadius: "20%",
                                  opacity: 0.9,
                                }}
                              />
                            </td>
                            <td
                              style={{ justifyContent: "right", width: "5%" }}
                            >
                              ロゴ、ステッカー、看板のデザイン、商用ホームページ作成（写真撮影含む）、SEO対策、ブログ作成、ブログ記事作成代行、商用スマホアプリ開発、YouTubeビデオ、および企業PRビデオの作成
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                )}
              </div>
              <div className={matches ? "row" : ""}>
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/oihi_farm"
                      style={{ color: "white" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="col-sm-6">
                        <ForestIcon
                          sx={{ fontSize: "3vw", marginBottom: "3px" }}
                        />
                        <br />
                        <p>Nature</p>
                      </div>
                      <div className="col-sm-1" style={{ right: "5vw" }}>
                        <img src={Img2} className="flexer-img-ig2" alt="ig" />
                      </div>
                      <div className="col-sm-6" style={{ textAlign: "left" }}>
                        <p style={{ fontWeight: 800, marginTop: -40 }}>
                          無農薬、無添加、無除草剤のサツマイモの自家栽培
                          『1代目さつまいも農園オイヒーファーム』
                        </p>
                      </div>
                    </a>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        borderRadius: "15%",
                        background:
                          "linear-gradient(to right, #262626 0%, #d8d8d8a8 100%)",
                      }}
                    >
                      <a
                        href="https://www.instagram.com/oihi_farm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <table
                          style={{
                            marginTop: "5%",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <tbody>
                            <tr
                              style={{
                                color: "white",
                              }}
                            >
                              <td
                                style={{ paddingLeft: "6%", fontSize: "10px" }}
                              >
                                <ForestIcon
                                  sx={{ fontSize: "8vw", marginBottom: "3px" }}
                                />
                                <br />
                                Nature
                              </td>
                              <td style={{ paddingLeft: "1%" }}>
                                <img
                                  src={Img2}
                                  className="flexer-img-ig img-responsive"
                                  alt="ig"
                                  style={{
                                    color: "white",
                                    borderRadius: "20%",
                                    opacity: 0.9,
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  justifyContent: "right",
                                  paddingLeft: "0px",
                                  width: "5%",
                                }}
                              >
                                無農薬、無添加、無除草剤のサツマイモの自家栽培
                                『1代目さつまいも農園オイヒーファーム』
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100vw",
                        marginTop: 10,
                        marginBottom: -10,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <video
                        onClick={() => {
                          handleOpen();
                        }}
                        title="Onetake's"
                        autoPlay
                        playsInline
                        src="https://one-takes.com/static/media/ad.89237f0d.mp4"
                        // src="http://localhost:3000/static/media/ad.89237f0d.mp4"
                        loop
                        muted
                        style={{
                          width: matches ? "20vw" : landscape ? "20vw" : "90vw",
                          borderRadius: "10px",
                          display: open ? "none" : "flex",
                        }}
                      />
                    </div>
                  </>
                )}
                {matches ? (
                  <div
                    className="col-sm-6"
                    style={{
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                      paddingTop: "5%",
                      marginTop: "5%",
                      paddingBottom: "3%",
                      borderRadius: "30%",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/yakiimooihi1"
                      style={{ color: "white" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="col-sm-6">
                        <Ramen sx={{ fontSize: "3vw" }} />
                        <p>Food and Health</p>
                      </div>
                      <div className="col-sm-1" style={{ right: "5vw" }}>
                        <img src={Img2} className="flexer-img-ig2" alt="ig" />
                      </div>
                      <div
                        className="col-sm-6"
                        style={{ textAlign: "left", marginTop: -40 }}
                      >
                        <p style={{ fontWeight: 800 }}>
                          焼き芋、焼き芋ジャム、犬のおやつの販売　
                          『焼き芋屋　ヤキイモオイヒー』
                        </p>
                      </div>
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "15%",
                      background:
                        "linear-gradient(to left, #262626 0%, #d8d8d8a8 100%)",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/yakiimooihi1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <table
                        style={{
                          marginTop: "5%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <tbody>
                          <tr
                            style={{
                              color: "white",
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "6%",
                                // paddingRight: "2%",
                                fontSize: "10px",
                              }}
                            >
                              <Ramen
                                sx={{ fontSize: "8vw", marginBottom: "7px" }}
                              />
                              <br />
                              Food and Health
                            </td>
                            <td style={{ paddingLeft: "1%" }}>
                              <img
                                src={Img2}
                                className="flexer-img-ig img-responsive"
                                alt="ig"
                                style={{
                                  color: "white",
                                  borderRadius: "20%",
                                  opacity: 0.9,
                                }}
                              />
                            </td>
                            <td
                              style={{ justifyContent: "right", width: "5%" }}
                            >
                              焼き芋、焼き芋ジャム、犬のおやつの販売　
                              『焼き芋屋　ヤキイモオイヒー』
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: matches ? 60 : 20,
                marginBottom: matches ? 60 : 20,
              }}
            >
              <img
                src={Mdg}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100vw",
                  height: "70.6797vw",
                }}
                className="img-responsive"
                alt=""
              />
            </div>
          </div>
          <div>
            {/* <Button
              onClick={() => {
                alert("zeh");
                handleOpen();
              }}
            >
              Open modal
            </Button> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{
                    height: 40,
                    width: "100%",
                    marginTop: "2.5vh",
                    textAlign: "right",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    position: "absolute",
                    zIndex: 9999,
                  }}
                >
                  <Close
                    style={{
                      height: 40,
                      width: 40,
                      margin: 10,
                      color: "rgba(255,255,255,0.6)",
                    }}
                    onClick={handleClose}
                  />
                </div>
                <Stories
                  stories={stories}
                  storyStyles={{ width: "100vw" }}
                  defaultInterval={1500}
                  loop={true}
                  keyboardNavigation={true}
                  onAllStoriesEnd={handleClose}
                  width="100%"
                  height="100vh"
                  // height={768}
                />
              </Box>
            </Modal>
          </div>
        </div>
        {matches && (
          <div
            style={{
              width: "96vw",
              height: "100vh",
              bottom: "1vh",
              justifyContent: "right",
              alignItems: "flex-end",
              display: "flex",
              position: "absolute"
            }}
          >
            <video
              onClick={() => {
                handleOpen();
              }}
              title="Onetake's"
              autoPlay
              src="https://one-takes.com/static/media/ad.89237f0d.mp4"
              // src="http://localhost:3000/static/media/ad.89237f0d.mp4"
              loop
              muted
              style={{
                width: matches ? "20vw" : landscape ? "20vw" : "50vw",
                position: "fixed",
                borderRadius: "10px",
                display: open ? "none" : "flex",
              }}
            />
          </div>
        )}
        <div id="footer" style={{ backgroundColor: "#321f63", width: "100%" }}>
          <div className="container text-center">
            <p>©︎ {year} Kabushikigaisha Onetakes</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
