import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import MenuIcon from "@mui/icons-material/Menu";
import Spu from "./imgs/spu2.webp";
import CloseIcon from "@mui/icons-material/Close";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
export const Navigation = (props) => {
  const [colorChange, setColorchange] = useState(false);
  const [homeAnimation, setHomeAnimation] = useState(false);
  const location = useLocation();
  const Navigate = useNavigate();
  //...
  useEffect(() => {
    if (location.pathname === "/") {
      setHomeAnimation(true);
    } else setHomeAnimation(false);
  }, [location]);
  const tablet = useMediaQuery("(min-height:450px)");
  const changeNavbarColor = () => {
    if (window.scrollY >= 600) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [open, setOpen] = useState(false);
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{
        backgroundColor: homeAnimation
          ? colorChange
            ? "#321f63"
            : "transparent"
          : "#321f63",
      }}
    >
      <div className="container">
        <div className="navbar-header" style={{ marginTop: "-20px" }}>
          <div
            type="button"
            className="navbar-toggle collapsed"
            style={{
              backgroundColor: "transparent",
              border: "transparent",
              color: "white",
              letterSpacing: "1px",
              marginRight: "0px",
              marginTop: "auto",
              marginBottom: "auto",
              top: 10,
            }}
            data-toggle="collapse"
            onClick={() => setOpen(true)}
          >
            メニュー &nbsp;
            <sub>
              <MenuIcon />
            </sub>
          </div>
          <div
            // type="button"
            className="navbar-toggle collapsed"
            style={{
              border: "none",
              right: tablet ? "50vw" : "60vw",
              bottom: 5,
              backgroundColor: "transparent",
            }}
          >
            <img
              src={Spu}
              onClick={() => Navigate("/")}
              style={{
                width: "5vw",
                height: "4.5vh",
                marginBottom: "-100px",
                visibility: homeAnimation
                  ? colorChange
                    ? "visible"
                    : "hidden"
                  : "visible",
              }}
              alt="ONETAKES"
            />
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Drawer
                {...props}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                  sx: {
                    backgroundColor: "#321f63",
                    width: 250,
                  },
                }}
              >
                <List>
                  <ListItem style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <CloseIcon
                      onClick={() => setOpen(!open)}
                      style={{
                        color: "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "bolder",
                      padding: "1px",
                    }}
                  >
                    <ListItem
                      button
                      onClick={() => setOpen(false)}
                      component={Link}
                      to="/"
                    >
                      <HomeIcon sx={{ margin: "10px" }} /> Home
                    </ListItem>
                  </ListItem>
                  <ListItem
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "bolder",
                      padding: "1px",
                    }}
                  >
                    <ListItem
                      button
                      onClick={() => setOpen(false)}
                      to="/about"
                      component={Link}
                    >
                      <InfoIcon sx={{ margin: "10px" }} />
                      会社概要
                    </ListItem>
                  </ListItem>
                  <ListItem
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "bolder",
                      padding: "1px",
                    }}
                  >
                    <ListItem
                      button
                      onClick={() => setOpen(false)}
                      component={Link}
                      to="/business-lineup"
                    >
                      <ContactsIcon sx={{ margin: "10px" }} /> 事業内容
                    </ListItem>
                  </ListItem>
                  <ListItem
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "bolder",
                      padding: "1px",
                    }}
                  >
                    <ListItem
                      button
                      onClick={() => setOpen(false)}
                      component={Link}
                      to="/principles"
                    >
                      <SelfImprovementIcon
                        sx={{ size: "large", margin: "10px" }}
                      />
                      経営理念
                    </ListItem>
                  </ListItem>
                </List>
              </Drawer>
            </Grid>
            <Grid item>
              <div
                style={{
                  position: "relative",
                  right: "70vw",
                  visibility: homeAnimation
                    ? colorChange
                      ? "visible"
                      : "hidden"
                    : "visible",
                }}
              >
                <img
                  onClick={() => Navigate("/")}
                  src={Spu}
                  style={{
                    width: "4rem",
                    height: "4rem",
                    marginBottom: "-200px",
                    cursor: "pointer",
                  }}
                  alt="ONETAKES"
                />
              </div>
              <Button
                style={{ fontSize: "15px", color: "white" }}
                onClick={() => setOpen(!open)}
              >
                メニュー &nbsp; <MenuIcon />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </nav>
  );
};
