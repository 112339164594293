import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = () => {
  const year = new Date().getFullYear();
  useEffect(() => {
    document.title = "Contact Us";
    if (window.location.pathname === "/contact") {
    document.body.style.backgroundColor = "#262626";
    }

  }, []);
  const [open, setOpen] = useState(false);
  const [{ name, email, message }, setState] = useState(initialState);
  const [validate, setValidate] = useState(false);
  const [validate2, setValidate2] = useState(false);
  const [mail, setMail] = useState("");
  const [messagex, setMessage] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    setValidate(true);
    if (messagex === "") setValidate2(true);
    else setValidate2(false);
    if (mail === "") setValidate(true);
    else setValidate(false);
    e.preventDefault();
    if (email !== "" && message !== "") {
      setOpen(true);
      emailjs
        .sendForm(
          "service_uv7d76u",
          "template_vd6ac8i",
          e.target,
          "qmD9qLVAtTIJ9pDMt"
        )
        .then(
          (result) => {
            setOpen(false);
            Swal.fire({
              title: "ご入力ありがとうございます！",
              text:
                result.text === "OK" &&
                "送信完了しました。返信までしばらくお待ちください",
              confirmButtonText: "完了",
            });
            console.log(result);
            clearState();
          },
          (error) => {
            setOpen(false);
            Swal.fire({
              title: "エラー!",
              text: error.text,
              confirmButtonText: "完了",
            });
            console.log(error);
          }
        );
    }
  };
  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: "5%", backgroundColor: "#262626" }}>
        <div className="container xyz30">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title" style={{ color: "white" }}>
                <h2></h2>
                <p className="xyz30">
                  ワンテイクスについてのお問い合わせはこちら
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="お名前・ニックネーム (省略可)"
                        // required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="返信用メールアドレス（必須）"
                        // required
                        // onChange={handleChange}
                        onChange={(e) => {
                          setMail(e.target.value);
                          setValidate(false);
                          handleChange(e);
                          Swal.fire({
                            title: "ご入力ありがとうございます！",
                            text:
                              
                              "送信完了しました。返信までしばらくお待ちください",
                            confirmButtonText: "完了",
                          });
                        }}
                      />
                      {validate && (
                        <h6
                          style={{
                            color: "orange",
                            fontWeight: 400,
                            fontSize: "16px",
                          }}
                          className="vibr"
                        >
                          入力してください
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="メッセージ（ご自由にご記入ください）"
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setValidate2(false);
                      handleChange(e);
                    }}
                    // required
                    // onChange={handleChange}
                  ></textarea>
                  {validate2 && (
                    <h6
                      style={{
                        color: "orange",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                      className="vibr"
                    >
                      入力してください
                    </h6>
                  )}
                  {/* <p className="help-block text-danger"></p> */}
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-xs"
                  style={{
                    fontSize: "80%",
                    marginBottom: "5%",
                  }}
                >
                  送信
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#321f63" }} />
      </Backdrop>
      <div id="footer" style={{ backgroundColor: "#321f63", width: "100%" }}>
        <div className="container text-center">
          <p>©︎ {year} Kabushikigaisha Onetakes</p>
        </div>
      </div>
    </div>
  );
};
