import { useNavigate } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import React, { useEffect, useState } from "react";
import { Contact } from "./contact";
import { motion } from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Anim from "./imgs/anipreview.webp";
import AnimOne from "./imgs/animone.webp";
// import GMap from "./imgs/googlemaps.jpg";
// import { Box } from "@mui/system";
import "./home.css";
import "./fonts.css";
import Img1 from "./imgs/motorcycle1.jpg";
import AnimScoot from "./imgs/aniscoot1.webp";
import AnimTruck from "./imgs/animtruck.webp";
import AnimSurf from "./imgs/Animsurf.webp";
import Img5 from "./imgs/motorcycle8.jpg";
import Img3 from "./imgs/motorcycle3.jpg";
import Img4 from "./imgs/motorcycle4.jpg";
import Spu from "./imgs/spu2.webp";
import { Container } from "react-bootstrap";

export const Home = () => {
  document.body.style.backgroundColor = "#321f63";
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "株式会社ワンテイクス";
    scroll.scrollTo(-1000, {
      duration: 500,
      delay: 50,
      smooth: true,
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }, []);
  const matches = useMediaQuery("(min-width:1020px)");
  const tablet = useMediaQuery("(min-height:450px)");
  const [once, setOnce] = useState(false);
  const [twice, setTwice] = useState(false);
  const [thrice, setThrice] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [zero, setZero] = useState(false);
  const [ref0, inView0] = useInView({
    threshold: 0,
  });
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [ref2, inView2] = useInView({
    threshold: 0,
  });
  const [ref3, inView3] = useInView({
    threshold: 0,
  });
  const [ref4, inView4] = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView0 === true) setZero(true);
  }, [inView0]);
  useEffect(() => {
    if (inView === true) setOnce(true);
  }, [inView]);
  useEffect(() => {
    if (inView2 === true) setTwice(true);
  }, [inView2]);
  useEffect(() => {
    if (inView3 === true) setThrice(true);
  }, [inView3]);
  useEffect(() => {
    if (inView4 === true) setFourth(true);
  }, [inView4]);
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    scroll.scrollTo(-1000, {
      duration: 300,
      delay: 50,
      smooth: true,
      offset: 50,
    });
  }, []);
  return (
    <motion.div
      initial={{
        opacity: 0,
        transition: { duration: 0 },
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.1 },
      }}
    >
      {/* <div style={{backgroundColor: "transparent"}}> */}

      {!matches && (
        <div
          style={{
            height: "120vh",
            marginTop: "-10vw",
            width: "100%",
            backgroundColor: "#321f63",
            zIndex: 1,
            position: "absolute",
          }}
        ></div>
      )}

      <div>
        {!matches ? (
          <>
            <div
              style={{
                width: "100%",
                height: "130vh",
                // marginBottom: "100vh",
                zIndex: 2,
                position: "relative",
              }}
              className={playAnimation ? "cardfather" : "transp"}
            >
              <div
                style={{
                  width: tablet ? "90vw" : "50vw",
                  height: tablet ? "100vh" : "120vh",
                  // marginBottom: "100vh",
                  marginTop: tablet ? "10vh" : 0,
                }}
                className={playAnimation ? "card" : ""}
              >
                <img
                  style={{
                    width: tablet ? "40vw" : "10vw",
                    // height: "80%",
                    marginTop: "20%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  src={Spu}
                  className={playAnimation ? "tezt" : ""}
                  alt="onetakes"
                  onLoad={() => setPlayAnimation(true)}
                />
                <h1
                  className={playAnimation ? "fiam xyz30" : ""}
                  style={{
                    color: "orange",
                    fontSize: "140%",
                    textAlign: "center",
                    textShadow: "20px",
                    width: "100%",
                  }}
                >
                  株式会社ワンテイクス
                </h1>
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1500}
                >
                  <div
                    className={playAnimation ? "anim" : ""}
                    style={{ width: "100%" }}
                  >
                    <div
                      className="xyz30"
                      style={{
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                        marginTop: "2vh",
                        textAlign: "center",
                        fontSize: "70%",
                        // fontFamily: "Garamond",
                        fontWeight: "bold",
                        width: "100%",
                        opacity: 0.9,
                      }}
                    >
                      ワンテイクスってどんな会社？ <br />
                      <KeyboardDoubleArrowDownIcon
                        style={{
                          textShadow: "2px 2px 4px #000000",
                          fontWeight: "bold",
                          fontSize: "200%",
                        }}
                      />
                    </div>
                  </div>
                </Link>
                <span
                  className={playAnimation ? "top" : "transp"}
                  style={{
                    marginTop: tablet ? "20vh" : "40vh",
                    height: "7px",
                    top: !matches ? 30 : 0,
                  }}
                ></span>
                <span className={playAnimation ? "bottom" : "transp"}></span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ height: "120vh" }}
              className={playAnimation ? "cardfather" : "transp"}
            >
              <div
                className={playAnimation ? "card" : ""}
                style={{ height: "100vh", marginTop: "20vh" }}
              >
                <img
                  className={playAnimation ? "tezt" : ""}
                  src={Spu}
                  alt="onetakes"
                  onLoad={() => setPlayAnimation(true)}
                />
                <h1
                  className={playAnimation ? "fiam xyz30" : ""}
                  style={{
                    color: "orange",
                    fontFamily: "Garamond",
                    // fontSize: "30px",
                    textAlign: "center",
                    fontSmooth: "10",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textShadow: "20px",
                    marginTop: "-5vh",
                  }}
                >
                  株式会社ワンテイクス
                </h1>
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1500}
                >
                  <div
                    className={playAnimation ? "anim" : ""}
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                        marginTop: "-3vh",
                        textAlign: "center",
                        fontSize: "150%",
                        fontFamily: "Garamond",
                        fontWeight: "lighter",
                        width: "100%",
                        opacity: 0.9,
                      }}
                    >
                      ワンテイクスってどんな会社？ <br />
                      <KeyboardDoubleArrowDownIcon
                        style={{
                          textShadow: "2px 2px 4px #000000",
                          fontWeight: "bold",
                          fontSize: "200%",
                        }}
                      />
                    </div>
                  </div>
                </Link>
                <span
                  className={playAnimation ? "top" : "transp"}
                  style={{ marginTop: "20vh", height: "7px" }}
                ></span>
                <span className={playAnimation ? "right" : "transp"}></span>
                <span className={playAnimation ? "bottom" : "transp"}></span>
                <span
                  style={{ visibility: "hidden" }}
                  className={playAnimation ? "left" : "transp"}
                ></span>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ backgroundColor: "#333333", color: "white" }}>
        <Container fluid>
          <div style={{ textAlign: "center", paddingTop: "50px" }}>
            <div className="row" id="section1">
              <div className="col-xs-12 col-md-12">
                <div className="about-text">
                  <div
                    style={{
                      display: "flex",
                      width: "90vw",
                      align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                    }}
                  >
                    <div ref={ref0} />
                    <div
                      className={zero ? "nnah" : ""}
                      style={{ width: matches && "100vh" }}
                    >
                      <img
                        src={AnimOne}
                        style={{
                          width: matches ? "20%" : "50vw",
                          height: tablet ? "10vh" : "20vh",
                          marginTop: "-15px",
                          visibility: !zero && "hidden",
                        }}
                        alt="ONETAKE"
                      />
                    </div>
                  </div>
                  <h1
                    className={zero ? "xyz20 disapp" : "xyz20"}
                    style={{
                      fontSize: !matches && "170%",
                      letterSpacing: "1vh",
                      fontStyle: "italic",
                      textShadow: "2px 2px 4px #000000",
                      visibility: !zero && "hidden",
                    }}
                  >
                    ONETAKE
                    <div
                      style={{
                        color: "white",
                        display: "inline-block",
                        padding: 4,
                        cursor: "pointer",
                        fontWeight: 10,
                        letterSpacing: 2,
                      }}
                      className="xyz30"
                      onClick={() => navigate("/business-lineup")}
                    >
                      <b>'</b>
                    </div>
                    S FOR ALL
                  </h1>
                  <br />
                  <h2
                    style={{
                      color: "white",
                      fontWeight: 100,
                      marginTop: "4vh",
                      // fontFamily: "Shadows Into Light",
                      // letterSpacing: "1vh",
                      fontSize: !matches ? "large" : "140%",
                    }}
                    className="xyz30"
                  >
                    総合的なライフスタイル創りを手がけるガレージ
                    <br />
                    より良い生活環境を
                    <div
                      style={{
                        color: "white",
                        display: "inline-block",
                        padding: 4,
                        cursor: "pointer",
                        fontWeight: 10,
                        letterSpacing: 2,
                      }}
                      className="xyz30"
                      onClick={() => navigate("/business-lineup")}
                    >
                      <b>ワンテイクス</b>
                    </div>
                    で
                    <br /> <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white" }}
                      href="https://goo.gl/maps/2nk8pQ3hmxgmFwAh8"
                    >
                      <div
                        style={{
                          color: "white",
                          display: "inline-block",
                          backgroundColor: "#404040",
                          borderRadius: "10px",
                          // border: "solid 1px white",
                          padding: 4,
                          cursor: "pointer",
                        }}
                        className="xyz20"
                      >
                        OYAMA
                        <div
                          style={{
                            color: "white",
                            display: "inline-block",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 10,
                          }}
                          className="xyz30"
                          onClick={() => navigate("/business-lineup")}
                        >
                          <b>, </b>
                        </div>
                        TOCHIGI
                        <div
                          style={{
                            color: "white",
                            display: "inline-block",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 10,
                          }}
                          className="xyz30"
                          onClick={() => navigate("/business-lineup")}
                        >
                          <b>, </b>
                        </div>
                        JAPAN
                      </div>
                    </a>
                  </h2>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div style={{ backgroundColor: "#262626" }}>
          {!matches ? (
            <div
              style={{
                textAlign: "center",
                // paddingTop: "50px",
                paddingBottom: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    // width: "100vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "95vw",
                      // align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: "20vh",
                    }}
                  >
                    <div ref={ref} />
                    <div
                      className={once ? "nnah" : ""}
                      style={{ opacity: !once && 0 }}
                    >
                      <img
                        src={Anim}
                        style={{
                          width: "50vw",
                          // height: tablet ? "10vh" : "30vh",
                          marginTop: "-15px",
                          visibility: !once & "hidden",
                        }}
                        alt="ONETAKE"
                      />
                    </div>
                  </div>
                  <div style={{ minHeight: "35vh" }}>
                    <img
                      src={zero ? Img1 : ""}
                      className={
                        once ? "img-responsive disapp-img" : "img-responsive"
                      }
                      alt="director"
                      style={{
                        visibility: !once && "hidden",
                        width: zero && "100vw",
                        height: "80.982vw",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="about-text">
                  <br />
                  <h2
                    style={{
                      color: "white",
                      fontWeight: "lighter",
                      fontSize: !matches ? "large" : "200%",
                      // fontFamily: "Caveat",
                      paddingTop: "5%",
                      letterSpacing: "1vh",
                      visibility: !once && "hidden",
                    }}
                    className="xyz1s"
                  >
                    Masaki Uetake
                    <div
                      style={{
                        borderTop: "gray 1px solid",
                        paddingTop: "3%",
                        marginTop: "3%",
                        color: "orange",
                      }}
                    >
                      Director
                    </div>
                  </h2>
                  {/* </div> */}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                paddingTop: "40px",
                paddingBottom: "23px",
                // marginLeft: "5vw",
                marginRight: "5vw",
              }}
              // className="container-fluid"
            >
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="about-text">
                    <br />
                    <br />

                    <div ref={ref} />
                    <h2
                      style={{
                        color: "white",
                        fontWeight: "lighter",
                        padding: "5%",
                        fontSize: "200%",
                        paddingTop: "20%",
                        // fontFamily: "Caveat",
                        letterSpacing: "1vh",
                      }}
                      className="xyz1s"
                    >
                      Masaki Uetake
                      <br />
                      <div
                        style={{
                          borderTop: "gray 1px solid",
                          paddingTop: "3%",
                          marginTop: "3%",
                          color: "orange",
                        }}
                      >
                        Director
                      </div>
                    </h2>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div
                    style={{
                      display: "flex",
                      width: "45vw",
                      // align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: matches ? "20vh" : "17vw",
                    }}
                  >
                    <div ref={ref} />
                    <div
                      className={once ? "nnah" : ""}
                      style={{ opacity: !once && 0 }}
                    >
                      <img
                        src={Anim}
                        style={{
                          width: !matches ? "50vw" : "13vw",
                          height: !matches ? "20vh" : "10vh",
                          marginTop: !matches ? "-15px" : "100px",
                          paddingTop: !matches && "-20px",
                          visibility: !once & "hidden",
                        }}
                        alt="director"
                      />
                    </div>
                  </div>
                  <img
                    src={Img1}
                    className={
                      once ? "img-responsive disapp-img" : "img-responsive"
                    }
                    alt="director"
                    style={{
                      visibility: !once && "hidden",
                      width: "40vw",
                      borderRadius: "5px",
                    }}
                  />{" "}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              paddingTop: matches && "30px",
              paddingBottom: matches ? "3%" : "3%",
              marginRight: matches && "5vw",
              paddingLeft: matches && "4vw",
              overflow: "hidden",
            }}
          >
            <div className={matches ? "row" : ""}>
              <div className={matches ? "col-xs-12 col-md-6" : ""}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "95vw",
                      align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: matches ? "20vh" : "17vw",
                    }}
                  >
                    <div ref={ref2} />
                    <div
                      className={twice ? "nnah" : ""}
                      style={{ opacity: !twice && 0 }}
                    >
                      <img
                        src={AnimTruck}
                        style={{
                          width: !matches ? (tablet ? "70vw" : "50vw") : "20vw",
                          // height: tablet ? "20vh" : "50vh",
                          marginTop: "-15px",
                          paddingTop: !matches && "-20px",
                          visibility: !twice & "hidden",
                        }}
                        alt="ONETAKE"
                      />
                    </div>
                  </div>
                  <div style={{ minHeight: "35vh" }}>
                    <img
                      src={zero ? Img3 : ""}
                      className={
                        twice ? "img-responsive disapp-img" : "img-responsive"
                      }
                      alt="engineer"
                      style={{
                        width: matches ? "40vw" : twice && "100vw",
                        height: !matches && "74.97972424979724vw",
                        borderRadius: "5px",
                        marginLeft: tablet ? 0 : "auto",
                        marginRight: tablet ? 0 : "auto",
                        visibility: !twice && "hidden",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={matches ? "col-xs-12 col-md-6" : ""}>
                <div
                  className="about-text"
                  style={{ paddingTop: matches && "10%" }}
                >
                  <br />
                  <h2
                    className="xyz1s"
                    style={{
                      color: "white",
                      fontWeight: "lighter",
                      fontSize: !matches ? "large" : "200%",
                      marginTop: matches ? "17%" : "5%",
                      // fontFamily: "Caveat",
                      letterSpacing: "1vh",
                      visibility: !twice && "hidden",
                    }}
                  >
                    Naoya Noguchi
                    <br />
                    <div
                      style={{
                        borderTop: "gray 1px solid",
                        paddingTop: "3%",
                        marginTop: "3%",
                        color: "#492b94",
                        textShadow: "1px 1px 1px #000000",
                      }}
                    >
                      Engineer
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#262626" }}>
          {!matches ? (
            <div
              style={{
                textAlign: "center",
                // paddingTop: "70px",
                paddingBottom: "0px",
              }}
            >
              <div>
                <div
                  style={
                    {
                      // marginRight: "auto",
                      // marginLeft: "auto",
                      // width: "100vw",
                    }
                  }
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      width: "95vw",
                      // align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: "9vh",
                    }}
                  >
                    <div ref={ref3} />
                    <div className={thrice ? "nnah" : ""}>
                      <img
                        src={AnimScoot}
                        style={{
                          width: "50vw",
                          // height: tablet ? "15vh" : "50vh",
                          marginTop: "-15px",
                          visibility: !thrice && "hidden",
                        }}
                        alt="ONETAKE"
                      />
                    </div>
                  </div>
                  <div style={{ minHeight: "35vh" }}>
                    <img
                      src={zero ? Img4 : ""}
                      className={
                        thrice ? "img-responsive disapp-img" : "img-responsive"
                      }
                      style={{
                        visibility: !thrice && "hidden",
                        borderRadius: "5px",
                        width: twice && "100vw",
                        height: "75vw",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                      }}
                      alt="designer"
                    />
                  </div>
                </div>
                <div>
                  <div className="about-text">
                    <br />
                    <h2
                      style={{
                        color: "white",
                        fontWeight: "lighter",
                        fontSize: !matches ? "large" : "",
                        paddingTop: "5%",
                        letterSpacing: "1vh",
                      }}
                      className="xyz1s"
                    >
                      Kaori Okamura <br />
                      <div
                        style={{
                          borderTop: "gray 1px solid",
                          paddingTop: "3%",
                          marginTop: "3%",
                          color: "#4dff88",
                        }}
                      >
                        Designer
                      </div>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                paddingTop: "40px",
                paddingBottom: "30px",
                marginRight: "5vw",
                paddingLeft: "4vw",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="about-text" style={{ marginTop: "5%" }}>
                    <h3
                      style={{
                        color: "white",
                        fontWeight: "lighter",
                        fontSize: "200%",
                        paddingTop: "20%",
                        letterSpacing: "1vh",
                      }}
                      className="xyz1s"
                    >
                      KAORI OKAMURA <br />
                      <div
                        style={{
                          borderTop: "gray 1px solid",
                          paddingTop: "3%",
                          marginTop: "3%",
                          color: "#4dff88",
                        }}
                      >
                        DESIGNER
                      </div>
                    </h3>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div
                    style={{
                      display: "flex",
                      width: "45vw",
                      // align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: matches ? "20vh" : "17vw",
                    }}
                  >
                    <div ref={ref3} />
                    <div
                      className={thrice ? "nnah" : ""}
                      style={{ opacity: !thrice && 0 }}
                    >
                      <img
                        src={AnimScoot}
                        style={{
                          width: !matches ? "50vw" : "17vw",
                          height: !matches ? "20vh" : "17vh",
                          marginTop: !matches ? "-15px" : "30px",
                          paddingTop: !matches && "-20px",
                          visibility: !thrice & "hidden",
                        }}
                        alt="designer"
                      />
                    </div>
                  </div>
                  <img
                    src={Img4}
                    className={
                      thrice ? "img-responsive disapp-img" : "img-responsive"
                    }
                    alt="designer"
                    style={{
                      borderRadius: "5px",
                      visibility: !thrice && "hidden",
                      paddingTop: "5vh",
                      width: "40vw",
                    }}
                  />{" "}
                </div>
              </div>
            </div>
          )}
          <br />
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              paddingTop: matches && "30px",
              paddingBottom: matches ? "3%" : "5%",
              paddingLeft: matches && "4vw",
              marginRight: matches && "5vw",
              overflow: "hidden",
            }}
          >
            <div className={matches ? "row" : ""}>
              <div className={matches ? "col-xs-12 col-md-6" : ""}>
                <div
                  style={
                    {
                      // marginRight: "auto",
                      // marginLeft: "auto",
                      // width: "100vw",
                    }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      width: "95vw",
                      align: "center",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      position: "absolute",
                      marginTop: matches ? "20vh" : "17vw",
                    }}
                  >
                    <div ref={ref4} />
                    <div
                      className={fourth ? "nnah" : ""}
                      style={{ opacity: !fourth && 0 }}
                    >
                      <img
                        src={AnimSurf}
                        style={{
                          width: !matches ? "70vw" : "20vw",
                          marginRight: !matches && 50,
                          paddingRight: matches && "5vw",
                          // height: tablet ? "20vh" : "40vh",
                          // marginTop: "-5px",
                          paddingTop: !matches && "-20px",
                          visibility: !fourth & "hidden",
                        }}
                        alt="ONETAKE"
                      />
                    </div>
                  </div>
                  <div style={{ minHeight: "35vh" }}>
                    <img
                      src={zero ? Img5 : ""}
                      className={
                        fourth ? "img-responsive disapp-img" : "img-responsive"
                      }
                      alt="surfer"
                      style={{
                        width: matches ? "40vw" : thrice && "100vw",
                        height: !matches && "75vw",
                        borderRadius: "5px",
                        visibility: !fourth && "hidden",
                        marginLeft: tablet ? 0 : "auto",
                        marginRight: tablet ? 0 : "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={matches ? "col-xs-12 col-md-6" : ""}>
                <div
                  className="about-text"
                  style={{ paddingTop: matches && "10%" }}
                >
                  <h2
                    className="xyz1s"
                    style={{
                      color: "white",
                      fontWeight: "lighter",
                      fontSize: !matches ? "large" : "200%",
                      marginTop: matches ? "17%" : "10%",
                      // fontFamily: "Caveat",
                      letterSpacing: "1vh",
                      visibility: !fourth && "hidden",
                    }}
                  >
                    HIDEAKI FURUSAWA
                    <br />
                    <div
                      style={{
                        borderTop: "gray 1px solid",
                        paddingTop: "3%",
                        marginTop: "3%",
                        color: "#cc6600",
                        textShadow: "1px 1px 1px #000000",
                      }}
                    >
                      Surfer
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#262626" }}>
        <Contact />
      </div>
      {/* </div> */}
    </motion.div>
  );
};
