import { Routes, Route } from "react-router-dom";
import { About } from "./components/about";
import { Home } from "./components/home";
import { Principles } from "./components/principles";
import { BusinessLineup } from "./components/business-lineup";
import { Contact } from "./components/contact";
import "./App.css";
import { Navigation } from "./components/navigation";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

const AnimatedRoutes = () => {
  return (
    <>
      <Navigation />
      <AnimatePresence>
        <Routes>
          <Route key="a" path="/" element={<Home />} />
          <Route key="c" path="/about" element={<About />} />
          <Route key="e" path="/principles" element={<Principles />} />
          <Route key="d" path="/business-lineup" element={<BusinessLineup />} />
          <Route key="f" path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default AnimatedRoutes;
